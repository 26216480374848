<template>
    <div>
        <price-chart :data="data" :options="options" v-bind="$attrs" v-if="_.get(data, 'datasets.0.data', []).length"></price-chart>
    </div>
</template>

<script>
import PriceChart from '../../../store/chart';
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: false,
            default: () => {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    elements: {
                        line: {
                            borderColor: '#4567fe',
                            fill: false
                        },
                        point: {
                            backgroundColor: '#fff',
                            borderColor: '#4567fe',
                            borderWidth: 2,
                            radius: 5
                        },
                        label: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: false,
                                maxTicksLimit: 3
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                maxTicksLimit: 3
                            }
                        }]
                    }
                }
            }
        }
    },
    name: "Chart",
    components: {PriceChart}
}
</script>
