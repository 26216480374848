<template>
  <div>
    <confirmation :question="$t('questions.favorites_remove')"
                  @true="removeFromFavorites"
    >
      <template v-slot:activator="{showDialog}">
        <b-button class="favorites-button d-inline-flex align-items-center pb-0"
                  variant="none"
                  v-b-tooltip:hover:top="$t('buttons.favorites_remove')"
                  v-if="isFavorite" @click="showDialog">
          <h6 class="align-items-center d-flex mb-0">
            <feather-icon icon="HeartIcon" fill="red" style="color: red" size="20"></feather-icon>
            <span v-if="withLabel">{{ $t('buttons.favorites_remove') }}</span>
          </h6>
        </b-button>
      </template>
    </confirmation>
    <b-dropdown v-if="!isFavorite" no-caret variant="none" toggle-class="favorites-button pb-0" lazy
                v-b-tooltip:hover:top="$t('buttons.add_to_favorites')"
    >
      <template #button-content>
        <h6 class="align-items-center d-flex mb-0">
          <feather-icon icon="HeartIcon" size="20" class="text-danger"></feather-icon>
          <span v-if="withLabel">{{ $t('buttons.add_to_favorites') }}</span>
        </h6>
      </template>
      <b-dropdown-item v-for="list in lists" :key="list.id" button @click="addToList(list.id)">
        {{ list.name }}
      </b-dropdown-item>
      <b-dropdown-divider v-if="lists.length"></b-dropdown-divider>
      <b-dropdown-item button @click="createListDialog = true">{{ $t('buttons.add_to_new_list') }}</b-dropdown-item>
    </b-dropdown>
    <create-list-modal v-model="createListDialog" @created="list => addToList(list.id)"></create-list-modal>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider, VBTooltip
} from "bootstrap-vue";
import Confirmation from "@/views/pricex/elements/Confirmation";
import _ from "lodash"
import {mapActions} from "vuex";
import CreateListModal from "@/views/pricex/elements/CreateListModal";

export default {
  components: {
    CreateListModal,
    BButton, BDropdown, BDropdownItem, BDropdownDivider,
    Confirmation
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  name: "AddToFavoritesButton",
  props: {
    withLabel: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      createListDialog: false,
      newList: {
        name: null
      }
    }
  },
  computed: {
    lists() {
      return this.$store.state.user.data.lists || [];
    },
    isFavorite() {
      let result = false;
      _.forEach(this.lists, list => {
        let index = _.findIndex(list.products, {id: this.product.id});
        if (index > -1) {
          result = true;
        }
      })

      return result;
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    }
  },
  methods: {
    ...mapActions('user', ['addProduct', 'removeProduct']),
    addToList(listId) {
      this.addProduct({
        list: _.find(this.lists, {id: listId}),
        product: this.product
      }).then(() => {
        this.$notify().success(this.$t('messages.product_added_to_favorites'), this.$t('Success'));
      }).catch(error => {
        this.$notify().error(_.get(error, 'response.data.error', error.message));
      })
    },
    removeFromFavorites() {
      this.removeProduct(this.product.id)
          .then(() => {
            this.$notify().success(this.$t('messages.product_removed'), this.$t('Success'));
          })
          .catch(error => {
            this.$notify().error(error.message, this.$t('messages.general.error'));
          })
    }
  }
}
</script>
<style scoped>
.feather-heart + span {
  margin-left: 5px;
  font-size: 110%;
}
</style>