<template>
  <b-form-rating v-model="lazyValue" class="bg-transparent" inline v-bind="$attrs" v-on="$listeners" variant="warning"></b-form-rating>
</template>

<script>
import {BFormRating} from "bootstrap-vue";

export default {
  components: {
    BFormRating
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    lazyValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  name: "Rating"
}
</script>
