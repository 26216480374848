<template>
  <div v-if="value">
    <slot v-bind:priceFormatted="price">
            <span class="price">
              <feather-icon icon="ArrowDownCircleIcon" class="text-success" v-if="icon && direction === 'down'" size="22"></feather-icon>
              <feather-icon icon="ArrowUpCircleIcon" class="text-danger" v-if="icon && direction === 'up'" size="22"></feather-icon>
              <feather-icon icon="MinusCircleIcon" class="text-secondary" v-if="icon && direction === null" size="22"></feather-icon>
              {{ formatNumber(_.get(price, '0.value')) }}<sup
                v-if="fractionDigits!==0">{{ _.get(price, '2.value') }}</sup>
              <span v-if="!disableCurrency">{{ _.get(price, '4.value') }}</span>
            </span>
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    fractionDigits: {
      type: Number,
      default: 2
    },
    locale: {
      type: String,
      default: 'bg-BG'
    },
    currency: {
      type: String,
      default: 'BGN'
    },
    disableCurrency: {
      required: false,
      default: false,
      type: Boolean
    },
    fromCents: {
      required: false,
      default: false,
      type: Boolean
    },
    thousandsSeparator: {
      required: false,
      default: ',',
      type: String
    },
    icon: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String
    }
  },
  name: "Price",
  computed: {
    price() {
      let value = this.value;
      if (this.fromCents) {
        value = this.value / 100;
      }
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: this.fractionDigits,
        maximumFractionDigits: this.fractionDigits
      }).formatToParts(value);
    }
  },
  methods: {
    formatNumber(v) {
      return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);
    }
  }
}
</script>
