<template>

  <b-sidebar
      v-bind="$attrs" v-on="$listeners"
      class="product-preview"
      ref="productPreview"
      body-class="card mb-0 pb-5"
      right
      :width="drawerSize"
      no-header
      backdrop
      shadow
  >

    <b-card class="d-flex flex-column product-preview-card m-0 mh-100" body-class="px-2 px-lg-3">
      <div class="w-100 product-preview-header d-inline-flex flex-row align-items-center w-100 p-1">
        <b-button @click="$emit('previous')" class="btn-icon col flex-grow-0" variant="primary" size="sm">
          <feather-icon icon="ArrowLeftIcon"></feather-icon>
        </b-button>
        <h2 class="text-center flex-grow-1 col m-0">{{ product.name }}</h2>
        <b-button @click="$emit('next')" class="btn-icon col flex-grow-0" variant="primary" size="sm">
          <feather-icon icon="ArrowRightIcon"></feather-icon>
        </b-button>
      </div>
      <p class="text-center">{{ $t('messages.seller', {name: product.website.name}) }}</p>
      <div class="image-container justify-content-center align-items-center text-center">
        <img :src="product.image" :alt="product.name" class="img-fluid" @error="replaceImage">
      </div>
      <div class="d-flex description flex-column align-items-center list-unstyled m-1"
           v-if="!readMoreActivated"
           v-html="product.description.slice(0, 200)"></div>
      <div class="d-flex description flex-column align-items-center list-unstyled m-1"
           v-if="readMoreActivated"
           v-html="product.description"></div>
      <div class="text-center" v-if="!readMoreActivated && product.description.length >= 200"><b-link @click="readMoreActivated = true">{{ $t('buttons.read_more') }}</b-link></div>
      <div class="text-center" v-if="readMoreActivated"><b-link @click="readMoreActivated = false">{{ $t('buttons.read_less') }}</b-link></div>
      <chart :data="productChartData" :key="chartKey" :height="150"></chart>
      <price :value="product.price / 100" class="price fw-bolder mb-2 mt-4"></price>
      <b-button :to="{name: 'products.view', params: {slug: slugify(product.name), product: product.id}}"
                variant="primary" size="lg"
                class="rounded-pill btn-block">
        {{ $t('buttons.view_more') }}
      </b-button>
      <b-button class="compare-button mb-2 btn-outline-primary rounded-pill mt-2 btn-block"
                variant="outline-primary"
                @click="toggleCompare"
      >
        <feather-icon :icon="productAddedToCompare ? 'CheckSquareIcon' : 'SquareIcon'"></feather-icon>
        {{ $t('buttons.compare') }}
      </b-button>
      <b-button variant="outline-secondary" class="btn-block rounded-pill mt-3" @click="closeDrawer">
        {{ $t('buttons.close') }}
      </b-button>
      <div v-if="similar.length">
        <h3 class="mt-5 mb-1">{{ $t('titles.similar_products') }}</h3>
        <div class="results-products pb-3">
          <product-listing :product="product"
                           v-for="product in similar"
                           :key="product.id"></product-listing>
        </div>
      </div>
      <div class="text-center pb-3">
        <ad-banner :width="300" :height="250" :key="adContainerIndex"></ad-banner>
      </div>
    </b-card>
  </b-sidebar>

</template>

<script>
import {BSidebar, BButton, BCard, BLink} from "bootstrap-vue";
import Price from "@/views/pricex/elements/Price";
import Chart from "@/views/pricex/elements/Chart";
import _ from "lodash"
import ProductListing from "@/views/pricex/elements/Products/ProductListing";
import {mapActions} from "vuex";
import AdBanner from "@/views/pricex/elements/AdBanner";

export default {
  components: {
    AdBanner,
    ProductListing,
    Chart,
    Price,
    BSidebar, BButton, BCard, BLink
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  name: "ProductPreview",
  data() {
    return {
      productChartData: {},
      chartKey: 0,
      similar: [],
      adContainerIndex: 0,
      readMoreActivated: false
    }
  },
  mounted() {
    this.loadProductItem();
    this.loadSimilar(this.product.id, 0, 2);
  },
  computed: {
    drawerSize() {
      let windowWidth = window.innerWidth;
      if (windowWidth >= 2560) {
        return '25%';
      }
      if (windowWidth >= 1440) {
        return '30%';
      }
      if (windowWidth >= 1024) {
        return '40%';
      }
      if (windowWidth >= 768) {
        return '50%';
      }
      return '100%';
    },
    productAddedToCompare() {
      return _.find(this.$store.state.compare.items, {id: this.product.id});
    }
  },
  methods: {
    ...mapActions('products', ['loadProduct']),
    loadProductItem() {
      this.loadProduct({product_id: this.product.id})
          .then(response => {
            this.productChartData = response.data.chart
            this.chartKey++
          })
    },
    loadSimilar(productId, from, size) {
      this.similar = [];
      this.$store.dispatch('search/getSimilar', {product_id: productId, size: size, from: from}).then(data => {
        for (let i = 0; i < data.hits.hits.length; i++) {
          this.similar.push(data.hits.hits[i]._source)
        }
      })
    },
    closeDrawer() {
      this.$emit('close')
    },
    toggleCompare() {
      if (!this.productAddedToCompare) {
        this.$store.dispatch('compare/add', this.product);
        return;
      }
      this.$store.dispatch('compare/remove', this.product.id);
    },
    replaceImage(e) {
      e.target.src = '/logo.svg';
    }
  },
  watch: {
    product() {
      this.loadProductItem();
      this.loadSimilar(this.product.id, 0, 2);
      this.adContainerIndex++
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.results-products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 30px;
}

h2 {
  font-size: 14px;
}

.image-container > img {
  max-height: 200px;
}

.price {
  font-size: 200%;
}
@include media-breakpoint-down(lg) {
  .product-preview-header {
    margin-top: 50px
  }
}

.b-sidebar-body {
  margin: 0.5rem 1rem;
}
</style>
