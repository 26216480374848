<template>
    <b-card no-body class="product d-flex flex-row flex-md-column col border p-1 rounded-lg shadow-sm mt-md-0 mb-0"
            v-if="product">
      <add-to-favorites-button class="favorites-container" :product="product"></add-to-favorites-button>

      <b-button class="compare-button position-absolute" :relative="false"
                variant="none"
                v-b-tooltip:hover:top="$t('tooltip.compare')"
                @click="toggleCompare"
      >
        <feather-icon icon="SquareIcon"
                      size="20"
                      :class="{'text-light': isDarkTheme}"
                      v-if="!productAddedToCompare"></feather-icon>
        <feather-icon icon="CheckSquareIcon"
                      size="20"
                      :class="{'text-light': isDarkTheme}"
                      v-if="productAddedToCompare"></feather-icon>
      </b-button>

      <div class="image-container d-flex justify-content-center align-items-center mt-2 mb-1 bg-white col-5 col-md-auto"
           @click="preview ? $emit('preview') : false">
        <img :src="mainImage" :alt="product.name" class="img-fluid" @error="replaceImage" loading="lazy">
      </div>
      <div class="d-flex flex-column mt-auto col-7 col-md-auto">
        <h4 class="product-title mt-2 mt-md-0">{{ product.name }}</h4>
        <div class="mt-auto d-flex flex-column">
          <div>
            <rating :value="product.rating.score" disabled no-border class="p-0" inline></rating>
          </div>
          <b-link class="text-start ps-0 font-small-1">{{ $t('messages.seller', {name: _.get(product, 'website.name')}) }}
          </b-link>
          <price :value="product.price / 100" class="price fw-bolder mb-1" icon :direction="product.price_direction"></price>
        </div>
        <b-overlay :show="productLinkLoading" rounded="pill">
          <template #overlay="">
            <b-spinner class="mr-1" small type="grow" variant="primary"></b-spinner>
          </template>
          <b-button @click="checkProductLink"
                    variant="primary"
                    class="rounded-pill btn-block">{{ $t('buttons.view_product') }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
</template>

<script>
import _ from 'lodash'
import {BButton, VBTooltip, BLink, BCard, BOverlay, BSpinner} from "bootstrap-vue";
import AddToFavoritesButton from "@/views/pricex/elements/Products/AddToFavoritesButton";
import Rating from "@/views/pricex/elements/Products/Rating";
import Price from "@/views/pricex/elements/Price";

export default {
  components: {
    Price,
    BButton, BLink, Rating, BCard, BOverlay, BSpinner,
    AddToFavoritesButton
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    preview: {
      type: Boolean,
      default: true
    },
    position: {
      type: Number,
      default: 0
    }
  },
  name: "ProductListing",
  data(){
    return {
      productLinkLoading: false
    }
  },
  computed: {
    mainImage() {
      return _.get(this.product, 'image');
    },
    productAddedToCompare() {
      return _.find(this.$store.state.compare.items, {id: this.product.id});
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    }
  },
  methods: {
    checkProductLink(){
      this.productLinkLoading = true;
      this.$router.push({name: 'products.view', params: {product: this.product.id, slug: this.slugify(this.product.name)}})
    },
    toggleCompare() {
      if (!this.productAddedToCompare) {
        this.$store.dispatch('compare/add', this.product);
        return;
      }
      this.$store.dispatch('compare/remove', this.product.id);
    },
    replaceImage(e) {
      e.target.src = '/logo.svg';
    }
  }
}
</script>

<style scoped lang="scss">

.product {
  position: relative;

  .favorites-container::v-deep {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    button.favorites-button {
      font-size: 170%;
      color: red;
      padding-right: 13px;
      padding-top: 9px;
    }

    .dropdown-menu {
      right: 0;
      left: auto;
    }
  }

  > .image-container {
    text-align: center;
    max-height: 200px;
    min-height: 140px;

    > img {
      max-height: 100%;
    }


    &:hover {
      cursor: pointer;
    }
  }

  .product-title {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    font-size: 170%;
  }

  .compare-button {
    top: 0;
    left: 0;
    padding-left: 12px;
    z-index: 2;
  }
}
</style>
