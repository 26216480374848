<template>
  <div>
    <slot name="activator" v-bind:showDialog="showDialog"></slot>
    <b-modal v-model="modalShown" width="30%"
             :ok-title="$t('general.yes')"
             :cancel-title="$t('general.no')"
             @ok="emitTrue"
             @cancel="emitFalse"
    >
      <div class="h4">
        <slot v-bind="question">
          {{ question }}
        </slot>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BModal} from "bootstrap-vue";
import VueI18n from "@/libs/i18n/index"

export default {
  components: {
    BModal
  },
  name: "Confirmation",
  props: {
    question: {
      type: String,
      required: false,
      default: VueI18n.t('questions.are_you_sure')
    },
    title: {
      type: String,
      required: false,
      default: VueI18n.t('title.confirmation')
    }
  },
  data() {
    return {
      modalShown: false,
    }
  },
  methods: {
    showDialog() {
      this.modalShown = true;
    },
    emitTrue() {
      this.$emit('true');
      this.modalShown = false;
    },
    emitFalse() {
      this.$emit('false');
      this.modalShown = false;
    }
  }
}
</script>
