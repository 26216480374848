<template>
  <b-modal v-model="lazyModelState"
           :title="$t('titles.create_new_list')"
           :cancel-title="$t('buttons.close')"
           :ok-title="$t('buttons.create')"
           @ok="createListItem"
  >
    <validation-observer ref="createListForm">
      <b-form
          @submit.prevent="createListItem"
      >
        <b-form-group :label="$t('label.list_name')">
          <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
          >
            <b-form-input :state="errors.length > 0 ? false:null" v-model="listName" autofocus></b-form-input>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from "@validations";
import {BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback} from "bootstrap-vue";
import {mapActions} from "vuex";

export default {
  components: {
    BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
    ValidationProvider, ValidationObserver
  },
  name: "CreateListModal",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      required,
      listName: null,
      lazyModelState: false
    }
  },
  methods: {
    ...mapActions('user', ['createList']),
    createListItem(formSubmitEvent) {
      formSubmitEvent.preventDefault()
      this.$refs.createListForm.validate().then(success => {
        if (!success) {
          return
        }
        this.createList({
          name: this.listName
        }).then(response => {
          this.$notify().success(this.$t('messages.new_list.created'))
          this.lazyModelState = false
          this.listName = null
          this.$emit('created', response.data)
        }).catch(() => {
          this.$notify().error(this.$t('errors.cant_create_list'))
        })
      })
    },
  },
  watch: {
    value: function (newValue) {
      this.lazyModelState = newValue
    },
    lazyModelState: function (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>